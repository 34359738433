import { useState, useCallback, useRef } from "react";
import { resolveExportTransfers } from "../api";
import { toast } from "sonner";
import { Button, Text, Title } from "@tigris/mesokit";
import { Posthog, TelemetryEvents } from "@tigris/common";
import { ErrorMessages } from "../utils/errorMessages";

const TOAST_ID = "DownloadTransferHistory";

const transformData = (data: string) => {
  const columns = data.split("\n")[0].concat("\n");
  const rows = data.split("\n").slice(1);

  const updatedRows = rows.map((row) => {
    const rowItems = row.split(",");

    rowItems[3] = rowItems[3] === "fiat" ? "" : rowItems[3];

    // remove "fiat/" (i.e. fiat/USD => USD, fiat/EUR => EUR)
    rowItems[6] = rowItems[6].replace(/fiat\/(\w+)/, "$1");
    rowItems[8] = rowItems[8].replace(/fiat\/(\w+)/, "$1");

    rowItems[10] = rowItems[10] === "fiat" ? "" : rowItems[10];

    return rowItems.join(",");
  });

  return columns.concat(updatedRows.join("\n"));
};

export const ProfileSettingsDownloadTransferHistory = () => {
  const [exportedData, setExportedData] = useState<string>("");
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);

  const handleDownload = useCallback(async () => {
    Posthog.capture(TelemetryEvents.transferHistoryDownloaded);

    let data = exportedData;

    // only call API if data has not yet been fetched
    if (!exportedData) {
      const exportTransfersResult = await resolveExportTransfers();

      if (exportTransfersResult.isErr()) {
        toast.error(exportTransfersResult.error, { id: TOAST_ID });
        return;
      }

      data = transformData(exportTransfersResult.value);
      setExportedData(data);
    }

    try {
      const blob = new Blob([data], {
        type: "text/csv",
      });
      const url = URL.createObjectURL(blob);
      const date = new Date().toISOString().split("T")[0];

      if (downloadLinkRef.current) {
        downloadLinkRef.current.href = url;
        downloadLinkRef.current.download = `meso-transfers-${date}.csv`;
        downloadLinkRef.current.click();

        //clean up
        URL.revokeObjectURL(url);
      }
    } catch {
      toast.error(
        ErrorMessages.downloadTransfers.UNABLE_TO_DOWNLOAD_TRANSFERS,
        { id: TOAST_ID },
      );
      return;
    }

    toast.success("Successfully downloaded transfers.", {
      id: TOAST_ID,
    });
  }, [exportedData]);

  return (
    <>
      <section data-testid="ProfileSettingsDownloadTransferHistory">
        <Title.Medium className="font-bold">Download History</Title.Medium>
        <Text>
          Download a CSV of your transfer history to save for your records.
        </Text>
      </section>
      <Button
        className="mt-4 px-4 text-sm font-bold"
        containerClassName="block"
        data-testid="ProfileSettingsDownloadTransferHistory:button"
        primary={false}
        onClick={() => handleDownload()}
      >
        Download Transfer History
      </Button>
      <a className="hidden" ref={downloadLinkRef}></a>
    </>
  );
};
