import {
  InternationalAddressSuggestions,
  InternationalLookupAddressDetail,
} from "./types";
import { err, ok, Result } from "neverthrow";
import { CountryCodeAlpha3, NonUSCountryCodeAlpha3 } from "../../types";
import { ErrorMessages } from "../errorMessages";
import { z } from "zod";
import { MesoKitContextValue } from "../../MesoKitContext";
import { Posthog, TelemetryEvents } from "@tigris/common";

type InternationalSmartyLookupResult = Result<
  InternationalLookupAddressDetail | InternationalAddressSuggestions,
  string
>;

const cyprusShortCodes: Record<string, string> = {
  Αμμόχωστος: "04",
  Κερύvεια: "06",
  Λάρνακα: "03",
  Λευκωσία: "01",
  Λεμεσός: "02",
  Πάφος: "05",
};

const latviaShortCodes: Record<string, string> = {
  "Aizkraukles novads": "002",
  "Alūksnes novads": "007",
  "Augšdaugavas novads": "111",
  "Ādažu novads": "011",
  "Balvu novads": "015",
  "Bauskas novads": "016",
  "Cēsu novads": "022",
  Daugavpils: "DGV",
  "Dienvidkurzemes novads": "112",
  "Dobeles novads": "026",
  "Gulbenes novads": "033",
  Jelgava: "JEL",
  "Jelgavas novads": "041",
  "Jēkabpils novads": "042",
  Jūrmala: "JUR",
  "Krāslavas novads": "047",
  "Kuldīgas novads": "050",
  "Ķekavas novads": "052",
  Liepāja: "LPX",
  "Limbažu novads": "054",
  "Līvānu novads": "056",
  "Ludzas novads": "058",
  "Madonas novads": "059",
  "Mārupes novads": "062",
  "Ogres novads": "067",
  "Olaines novads": "068",
  "Preiļu novads": "073",
  Rēzekne: "REZ",
  "Rēzeknes novads": "077",
  "Rīgas novads": "078",
  Rīga: "RIX",
  "Ropažu novads": "080",
  "Saldus novads": "083",
  "Siguldas novads": "084",
  "Talsu novads": "085",
  "Tukuma novads": "087",
  "Valmieras novads": "089",
  Ventspils: "VEN",
  "Ventspils novads": "090",
  "Vērgales novads": "091",
};

const maltaShortCodes: Record<string, string> = {
  Attard: "01",
  Balzan: "02",
  Birgu: "03",
  Birkirkara: "04",
  Birżebbuġa: "05",
  Bormla: "06",
  Dingli: "07",
  Fgura: "08",
  Floriana: "09",
  Fontana: "10",
  Gudja: "11",
  Gżira: "12",
  Għajnsielem: "13",
  Għarb: "14",
  Għargħur: "15",
  Għasri: "16",
  Għaxaq: "17",
  Ħamrun: "18",
  Iklin: "19",
  Isla: "20",
  Kalkara: "21",
  Kerċem: "22",
  Kirkop: "23",
  Lija: "24",
  Luqa: "25",
  Marsa: "26",
  Marsaskala: "27",
  Marsaxlokk: "28",
  Mdina: "29",
  Mellieħa: "30",
  Mġarr: "31",
  Mosta: "32",
  Mqabba: "33",
  Msida: "34",
  Mtarfa: "35",
  Munxar: "36",
  Nadur: "37",
  Naxxar: "38",
  Paola: "39",
  Pembroke: "40",
  Pietà: "41",
  Qala: "42",
  Qormi: "43",
  Qrendi: "44",
  "Rabat Għawdex": "45",
  "Rabat (Ghawdex)": "45",
  Rabat: "46",
  "Rabat Malta": "46",
  Safi: "47",
  "San Ġiljan": "48",
  "San Ġwann": "49",
  "San Lawrenz": "50",
  "San Pawl il-Baħar": "51",
  Sannat: "52",
  "Santa Luċija": "53",
  "Santa Venera": "54",
  Siġġiewi: "55",
  Sliema: "56",
  Swieqi: "57",
  "Ta' Xbiex": "58",
  Tarxien: "59",
  "Il-Belt Valletta": "60",
  Valletta: "60",
  Xagħra: "61",
  Xewkija: "62",
  Xgħajra: "63",
  Żabbar: "64",
  "Żebbuġ Għawdex": "65",
  "Żebbuġ Malta": "66",
  Żejtun: "67",
  Żurrieq: "68",
};

const sloveniaShortCodes: Record<string, string> = {
  Ajdovščina: "001",
  Ankaran: "213",
  Apače: "195",
  Beltinci: "002",
  Benedikt: "148",
  "Bistrica ob Sotli": "149",
  Bled: "003",
  Bloke: "150",
  Bohinj: "004",
  Borovnica: "005",
  Bovec: "006",
  Braslovče: "151",
  Brda: "007",
  Brezovica: "008",
  Brežice: "009",
  Cankova: "152",
  Celje: "011",
  "Cerklje na Gorenjskem": "012",
  Cerknica: "013",
  Cerkno: "014",
  Cerkvenjak: "153",
  Cirkulane: "196",
  Črenšovci: "015",
  "Črna na Koroškem": "016",
  Črnomelj: "017",
  Destrnik: "018",
  Divača: "019",
  Dobje: "154",
  Dobrepolje: "020",
  Dobrna: "155",
  "Dobrova-Polhov Gradec": "021",
  Dobrovnik: "156",
  "Dol pri Ljubljani": "022",
  "Dolenjske Toplice": "157",
  Domžale: "023",
  Dornava: "024",
  Dravograd: "025",
  Duplek: "026",
  "Gorenja Vas-Poljane": "027",
  Gorišnica: "028",
  Gorje: "207",
  "Gornja Radgona": "029",
  "Gornji Grad": "030",
  "Gornji Petrovci": "031",
  Grad: "158",
  Grosuplje: "032",
  Hajdina: "159",
  "Hoče-Slivnica": "160",
  Hodoš: "161",
  Horjul: "162",
  Hrastnik: "034",
  "Hrpelje-Kozina": "035",
  Idrija: "036",
  Ig: "037",
  "Ilirska Bistrica": "038",
  "Ivančna Gorica": "039",
  Izola: "040",
  Jesenice: "041",
  Jezersko: "163",
  Juršinci: "042",
  Kamnik: "043",
  "Kanal ob Soči": "044",
  Kidričevo: "045",
  Kobarid: "046",
  Kobilje: "047",
  Kočevje: "048",
  Komen: "049",
  Komenda: "164",
  Koper: "050",
  "Kostanjevica na Krki": "197",
  Kostel: "165",
  Kozje: "051",
  Kranj: "052",
  "Kranjska Gora": "053",
  Križevci: "166",
  Krško: "054",
  Kungota: "055",
  Kuzma: "056",
  Laško: "057",
  Lenart: "058",
  Lendava: "059",
  Litija: "060",
  Ljubljana: "061",
  Ljubno: "062",
  Ljutomer: "063",
  "Log-Dragomer": "208",
  Logatec: "064",
  "Loška Dolina": "065",
  "Loški Potok": "066",
  "Lovrenc na Pohorju": "167",
  Luče: "067",
  Lukovica: "068",
  Majšperk: "069",
  Makole: "198",
  Maribor: "070",
  Markovci: "168",
  Medvode: "071",
  Mengeš: "072",
  Metlika: "073",
  Mežica: "074",
  "Miklavž na Dravskem Polju": "169",
  "Miren-Kostanjevica": "075",
  Mirna: "212",
  "Mirna Peč": "170",
  Mislinja: "076",
  "Mokronog-Trebelno": "199",
  Moravče: "077",
  "Moravske Toplice": "078",
  Mozirje: "079",
  "Murska Sobota": "080",
  Muta: "081",
  Naklo: "082",
  Nazarje: "083",
  "Nova Gorica": "084",
  "Novo Mesto": "085",
  Odranci: "086",
  Oplotnica: "171",
  Ormož: "087",
  Osilnica: "088",
  Pesnica: "089",
  Piran: "090",
  Pivka: "091",
  Podčetrtek: "092",
  Podlehnik: "172",
  Podvelka: "093",
  Poljčane: "200",
  Polzela: "173",
  Postojna: "094",
  Prebold: "174",
  Preddvor: "095",
  Prevalje: "175",
  Ptuj: "096",
  Puconci: "097",
  "Rače-Fram": "098",
  Radeče: "099",
  Radenci: "100",
  "Radlje ob Dravi": "101",
  Radovljica: "102",
  "Ravne na Koroškem": "103",
  Razkrižje: "176",
  "Rečica ob Savinji": "201",
  "Renče-Vogrsko": "209",
  Ribnica: "104",
  "Ribnica na Pohorju": "177",
  "Rogaška Slatina": "106",
  Rogašovci: "105",
  Rogatec: "107",
  Ruše: "108",
  "Selnica ob Dravi": "178",
  Semič: "109",
  Sevnica: "110",
  Sežana: "111",
  "Slovenj Gradec": "112",
  "Slovenska Bistrica": "113",
  "Slovenske Konjice": "114",
  Sodražica: "179",
  Solčava: "180",
  "Središče ob Dravi": "202",
  Starše: "115",
  Straža: "210",
  "Sveta Ana": "181",
  "Sveta Trojica v Slovenskih Goricah": "203",
  "Sveti Andraž v Slovenskih Goricah": "116",
  "Sveti Jurij ob Ščavnici": "033",
  "Sveti Jurij v Slovenskih Goricah": "204",
  "Sveti Tomaž": "205",
  Šalovci: "117",
  "Šempeter-Vrtojba": "118",
  Šenčur: "119",
  Šentilj: "120",
  Šentjernej: "121",
  Šentjur: "122",
  Šentrupert: "206",
  Škocjan: "123",
  "Škofja Loka": "124",
  Škofljica: "125",
  "Šmarje pri Jelšah": "126",
  "Šmarješke Toplice": "211",
  "Šmartno ob Paki": "127",
  "Šmartno pri Litiji": "194",
  Šoštanj: "128",
  Štore: "129",
  Tolmin: "130",
  Trbovlje: "131",
  Trebnje: "132",
  "Trnovska Vas": "182",
  Trzin: "183",
  Tržič: "134",
  Turnišče: "135",
  Velenje: "136",
  "Velika Polana": "184",
  "Velike Lašče": "137",
  Veržej: "185",
  Videm: "138",
  Vipava: "139",
  Vitanje: "140",
  Vodice: "141",
  Vojnik: "142",
  Vransko: "186",
  Vrhnika: "143",
  Vuzenica: "144",
  "Zagorje ob Savi": "146",
  Zavrč: "187",
  Zreče: "147",
  Žalec: "190",
  Železniki: "191",
  Žetale: "192",
  Žiri: "193",
  Žirovnica: "188",
  Žužemberk: "189",
};

// Smarty does not return administrative_area_short for Cyprus, Latvia, Malta, or Slovenia so we need to perform a manual lookup based on administrative_area (and/or locality for Malta)
const administrativeAreaShortLookup = (
  countryCode: NonUSCountryCodeAlpha3,
  administrative_area: string,
  locality: string,
): string | undefined => {
  let administrativeAreaShort = "";

  // Cyprus
  if (countryCode === CountryCodeAlpha3.CYP) {
    administrativeAreaShort = cyprusShortCodes[administrative_area];
  }
  // Latvia
  if (countryCode === CountryCodeAlpha3.LVA) {
    administrativeAreaShort = latviaShortCodes[administrative_area];
  }
  // Malta
  if (countryCode === CountryCodeAlpha3.MLT) {
    // It is common for localities in Malta to be prefixed with Il-, Is-, Tal-, L-, Ir-, Ħal, or Ħaż-
    const localityWithoutPrefix = locality.replace(
      /^(Il-|Is-|Tal-|L-|Ir-|Ħal |Ħaż-|Iż-)/,
      "",
    );

    administrativeAreaShort =
      maltaShortCodes[administrative_area] ||
      maltaShortCodes[locality] ||
      maltaShortCodes[localityWithoutPrefix];
  }
  // Slovenia
  if (countryCode === CountryCodeAlpha3.SVN) {
    administrativeAreaShort = sloveniaShortCodes[administrative_area];
  }

  if (administrativeAreaShort && administrativeAreaShort.length > 0) {
    return administrativeAreaShort;
  }

  Posthog.capture(
    TelemetryEvents.onboardingAdministrativeAreaShortCodeUnknown,
    {
      countryCode,
      administrativeArea: administrative_area,
      locality,
    },
  );

  return undefined;
};

// Top-level results
const internationalAddressLookupResult = z.object({
  candidates: z.array(
    z.object({
      entries: z.number(),
      address_text: z.string(),
      address_id: z.string(),
    }),
  ),
});

const internationalAddressDetailLookupResult = z.object({
  candidates: z.array(
    z.object({
      administrative_area: z.string(),
      // Certain countries may not return this
      administrative_area_long: z.optional(z.string()),
      // Certain countries (Cyprus, Latvia, Malta, Slovenia) may not return this
      administrative_area_short: z.optional(z.string()),
      country_iso3: z.nativeEnum(CountryCodeAlpha3),
      // Certain countries (Bulgaria, Iceland) may not return this
      locality: z.optional(z.string()),
      postal_code: z.string(),
      street: z.string(),
    }),
  ),
});

// The smarty SDK does not surface administrative_area_long or administrative_area_short, so we call the API directly https://github.com/smarty/smartystreets-javascript-sdk/pull/99
const baseUrl = "https://international-autocomplete.api.smarty.com/v2/lookup/";
export const internationalSmartyLookup = async ({
  search,
  addressId,
  country,
  sentry,
}: {
  search: string;
  addressId?: string;
  country: NonUSCountryCodeAlpha3;
  sentry?: MesoKitContextValue["sentry"];
}): Promise<InternationalSmartyLookupResult> => {
  // const isDetailLookup = addressId != undefined;
  const searchParams = new URLSearchParams({
    search,
    country,
    max_results: "10",
    key: import.meta.env.VITE_SMARTY_AUTH_ID,
  });

  const url = new URL(
    `${baseUrl}${addressId ?? ""}?${searchParams.toString()}`,
  );
  const res = await fetch(url.toString(), {
    headers: {
      "content-type": "application/json; charset=utf-8",
    },
  });

  if (res.status !== 200) {
    let responseBody = "";

    if (res.headers.get("content-type")?.includes("application/json")) {
      responseBody = await res.json();
    }

    sentry?.captureMessage(ErrorMessages.smartyLookup.GENERIC_API_ERROR, {
      extra: {
        status: res.status,
        params: searchParams.toString(),
        responseBody,
      },
    });

    return err(ErrorMessages.smartyLookup.GENERIC_API_ERROR);
  }

  const address = await res.json();
  const candidates = address.candidates || [];

  if (candidates.length === 0) {
    return ok([]);
  }

  // Just because we have an `addressId`, doesn't mean we are making a request for a specific address.
  // All addressId does is further narrow down the search results.
  if ("street" in address.candidates[0]) {
    const parsed = internationalAddressDetailLookupResult.safeParse(address);
    if (parsed.success) {
      const [candidate] = parsed.data.candidates;
      const detail: InternationalLookupAddressDetail = {
        street: candidate.street,
        locality: candidate.locality,
        administrativeArea: candidate.administrative_area,
        administrativeAreaShort: candidate.administrative_area_short
          ? candidate.administrative_area_short
          : administrativeAreaShortLookup(
              country,
              candidate.administrative_area,
              candidate.locality ?? "",
            ),
        administrativeAreaLong: candidate.administrative_area_long,
        postalCode: candidate.postal_code,
        countryIso3: candidate.country_iso3,
      };

      return ok(detail);
    }
  } else {
    const parsed = internationalAddressLookupResult.safeParse(address);
    if (parsed.success) {
      return ok(
        parsed.data.candidates.map(({ address_id, address_text, entries }) => ({
          addressText: address_text,
          addressId: address_id,
          entries,
        })),
      );
    }
  }

  sentry?.captureMessage(ErrorMessages.smartyLookup.GENERIC_API_ERROR, {
    extra: {
      status: res.status,
      params: searchParams.toString(),
      responseBody: address,
    },
  });

  return err(ErrorMessages.smartyLookup.GENERIC_API_ERROR);
};
