import { CustomToastError } from "./CustomToastError";

export const UnsupportedIssuingCountryToastError = () => (
  <CustomToastError
    title="The card you entered is issued in a country Meso does not support."
    body={
      <div>
        Please try a different card. Learn about our{" "}
        <a
          href="https://support.meso.network/hc/en-us/articles/18411224896027-Product-Availability"
          target="_blank"
          rel="noreferrer"
          className="underline opacity-80 transition-opacity hover:opacity-100"
        >
          supported countries
        </a>
        .
      </div>
    }
  />
);
