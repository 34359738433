import NetworkFeeDisclosure from "./NetworkFeeDisclosure";
import { AnimatePresence, Variants, motion } from "framer-motion";
import { AnimatedCounter } from "./AnimatedCounter";
import { TransferKind, Money } from "../../generated/schema";
import { twMerge } from "tailwind-merge";
import {
  networkFromExchangeRate,
  isEVMBlockchain,
  ExchangeRate,
  cryptoAssetFromExchangeRate,
} from "@tigris/common";
import {
  PropsWithChildren,
  ReactElement,
  cloneElement,
  forwardRef,
  isValidElement,
} from "react";
import {
  formatCurrency,
  formatCurrencySymbol,
} from "../../utils/formatCurrency";

type LineItemsProps = {
  expanded?: boolean;
  exchangeRate?: ExchangeRate;
  mesoFee?: Money;
  mesoFeeWaived?: boolean;
  networkFee?: Money;
  networkFeeWaived?: boolean;
  subtotal?: Money;
  total?: Money;
  transferKind: TransferKind;
};

const skeletonLineVariants: Variants = {
  initial: { opacity: 0 },
  animate: {
    opacity: [0.4, 0.8, 0.4],
    transition: {
      repeat: Infinity,
      repeatType: "loop",
      duration: 1.4,
      ease: "linear",
    },
  },
  exit: { opacity: 0 },
};

const lineItemValueVariants: Variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
};

const lineItemValueAnimateProps = {
  variants: lineItemValueVariants,
  initial: "initial",
  animate: "animate",
};

const lineItemClassName = "relative flex items-center justify-between h-4";

const skeletonClassName =
  "bg-gray-400 text-gray-400 rounded-3xl opacity-20 select-none";

type SkeletonLoaderWrapperProps = {
  loading: boolean;
  keyPrefix: string;
};

const SkeletonLoaderWrapper = forwardRef<
  HTMLDivElement,
  PropsWithChildren<SkeletonLoaderWrapperProps>
>(({ loading, keyPrefix, children }, ref) => {
  return loading ? (
    <motion.span
      key={`${keyPrefix}Loader`}
      data-testid={`${keyPrefix}Loader`}
      variants={skeletonLineVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      className={skeletonClassName}
    >
      ------
    </motion.span>
  ) : (
    isValidElement(children) &&
      cloneElement(children as ReactElement, {
        ref,
        key: `${keyPrefix}Value`,
        "data-testid": `${keyPrefix}Value`,
      })
  );
});

export const LineItems = ({
  /** Whether to render the line items with tighter spacing and smaller font size. */
  expanded = false,
  exchangeRate,
  mesoFee,
  mesoFeeWaived,
  networkFee,
  networkFeeWaived,
  subtotal,
  total,
  transferKind,
}: LineItemsProps) => {
  const asset = exchangeRate && cryptoAssetFromExchangeRate(exchangeRate);
  const network = exchangeRate && networkFromExchangeRate(exchangeRate);
  const isCashIn = transferKind === TransferKind.CASH_IN;
  const formattedRate =
    (exchangeRate && Number(exchangeRate.rate).toFixed(4)) || "";

  // "fiat:x/iso4217:USD" => USD, "fiat:x/iso4217:EUR" => EUR
  const exchangeRateCurrency =
    exchangeRate?.quote.match(/iso4217:[^\s]+/)?.[0].split(":")?.[1] || "USD";

  return isCashIn ? (
    <div
      className={twMerge(
        "line-items flex flex-col",
        expanded ? "h-full gap-3 text-sm" : "gap-1 text-[13px] tracking-tight",
      )}
    >
      <div className={lineItemClassName}>
        <SkeletonLoaderWrapper
          keyPrefix="networkFeeLabel"
          loading={!exchangeRate || !network}
        >
          <motion.div
            {...lineItemValueAnimateProps}
            className="flex items-center gap-1"
          >
            <div className="font-medium opacity-70">
              {isEVMBlockchain(network!) ? "Gas Fee " : "Network Fee "}
            </div>
            <NetworkFeeDisclosure size={expanded ? "1x" : "sm"} />
          </motion.div>
        </SkeletonLoaderWrapper>

        <div className="flex items-center gap-1">
          <AnimatePresence mode="popLayout">
            <SkeletonLoaderWrapper
              keyPrefix="networkFee"
              loading={!exchangeRate}
            >
              <motion.span
                {...lineItemValueAnimateProps}
                className="font-medium tabular-nums opacity-70"
              >
                {networkFeeWaived ? (
                  <span className="line-through opacity-50">
                    {formatCurrency(networkFee?.amount, networkFee?.currency)}
                  </span>
                ) : (
                  <AnimatedCounter
                    value={Number(networkFee?.amount)}
                    formattedValue={formatCurrency(
                      networkFee?.amount,
                      networkFee?.currency,
                    )}
                  />
                )}
              </motion.span>
            </SkeletonLoaderWrapper>
          </AnimatePresence>
        </div>
      </div>

      <div className={lineItemClassName}>
        <div className="font-medium opacity-70">Processing Fee</div>
        <div className="font-medium tabular-nums">
          <AnimatePresence mode="popLayout">
            <SkeletonLoaderWrapper keyPrefix="mesoFee" loading={!exchangeRate}>
              <motion.span {...lineItemValueAnimateProps}>
                {mesoFeeWaived ? (
                  <span className="line-through opacity-50">
                    {formatCurrency(mesoFee?.amount, mesoFee?.currency)}
                  </span>
                ) : (
                  <AnimatedCounter
                    value={Number(mesoFee?.amount)}
                    formattedValue={formatCurrency(
                      mesoFee?.amount,
                      mesoFee?.currency,
                    )}
                  />
                )}
              </motion.span>
            </SkeletonLoaderWrapper>
          </AnimatePresence>
        </div>
      </div>

      <div className={lineItemClassName}>
        <div className="font-medium opacity-70">Subtotal</div>
        <div className="font-medium tabular-nums">
          <AnimatePresence mode="popLayout">
            <SkeletonLoaderWrapper keyPrefix="subtotal" loading={!exchangeRate}>
              <div className="box-border flex items-center gap-2 align-middle">
                <div className="rounded-md border border-neutral-800/25 px-1 text-xs font-medium tabular-nums text-neutral-800/50 dark:border-neutral-100/25 dark:text-white/50">
                  {asset} ≈ {formatCurrencySymbol(exchangeRateCurrency)}
                  <AnimatedCounter
                    value={Number(formattedRate)}
                    className="inline"
                  />
                </div>
                <div className="font-medium tabular-nums tracking-tight">
                  {formatCurrency(subtotal?.amount, subtotal?.currency)}
                </div>
              </div>
            </SkeletonLoaderWrapper>
          </AnimatePresence>
        </div>
      </div>

      {expanded && <div className="spacer my-1 h-px bg-transparent" />}

      <div
        className={twMerge(
          lineItemClassName,
          expanded && "mt-auto text-[1rem]",
        )}
      >
        <div data-testid="totalLoader" className="font-bold tracking-tight">
          Total
        </div>
        <AnimatePresence mode="popLayout">
          <motion.span
            key="totalValue"
            data-testid="totalValue"
            {...lineItemValueAnimateProps}
          >
            <div className="font-bold tabular-nums tracking-tight">
              {formatCurrency(total?.amount, total?.currency)}
            </div>
          </motion.span>
        </AnimatePresence>
      </div>
    </div>
  ) : (
    <div
      className={twMerge(
        "flex flex-col text-neutral-800 dark:text-neutral-100",
        expanded ? "h-full gap-3 text-sm" : "gap-1 text-[13px] tracking-tight",
      )}
    >
      <div className={lineItemClassName}>
        <SkeletonLoaderWrapper
          keyPrefix="networkFeeLabel"
          loading={!exchangeRate || !network}
        >
          <motion.div
            {...lineItemValueAnimateProps}
            className="flex items-center gap-1"
          >
            <div className="font-medium opacity-70">
              {isEVMBlockchain(network!) ? "Gas Fee " : "Network Fee "}
            </div>
          </motion.div>
        </SkeletonLoaderWrapper>
        <div data-testid="networkFeeValue" className="font-medium opacity-50">
          Calculated in wallet
        </div>
      </div>

      <div className={lineItemClassName}>
        <div className="font-medium opacity-70">Selling</div>
        <div className="font-medium tabular-nums">
          <AnimatePresence mode="popLayout">
            <SkeletonLoaderWrapper keyPrefix="subtotal" loading={!exchangeRate}>
              <div className="box-border flex items-center gap-2 align-middle">
                <div className="rounded-md border border-neutral-800/25 px-1 text-xs font-medium tabular-nums text-neutral-800/50 dark:border-neutral-100/25 dark:text-white/50">
                  {asset} ≈ {formatCurrencySymbol(exchangeRateCurrency)}
                  <AnimatedCounter
                    value={Number(formattedRate)}
                    className="inline"
                  />
                </div>
                <div className="font-medium tabular-nums tracking-tight">
                  {formatCurrency(subtotal?.amount, subtotal?.currency)}
                </div>
              </div>
            </SkeletonLoaderWrapper>
          </AnimatePresence>
        </div>
      </div>

      <div className={lineItemClassName}>
        <div className="font-medium opacity-70">Processing Fee</div>
        <div className="font-medium tabular-nums">
          <AnimatePresence mode="popLayout">
            <SkeletonLoaderWrapper keyPrefix="mesoFee" loading={!exchangeRate}>
              <motion.span {...lineItemValueAnimateProps}>
                {mesoFeeWaived ? (
                  <span className="line-through opacity-50">
                    {formatCurrency(mesoFee?.amount, mesoFee?.currency)}
                  </span>
                ) : (
                  <AnimatedCounter
                    value={Number(mesoFee?.amount)}
                    formattedValue={formatCurrency(
                      mesoFee?.amount,
                      mesoFee?.currency,
                    )}
                  />
                )}
              </motion.span>
            </SkeletonLoaderWrapper>
          </AnimatePresence>
        </div>
      </div>

      {expanded && <div className="spacer my-1 h-px bg-transparent" />}

      <div
        className={twMerge(
          lineItemClassName,
          expanded && "mt-auto text-[1rem]",
        )}
      >
        <div className="font-bold tracking-tight">Receiving</div>
        <AnimatePresence mode="popLayout">
          <SkeletonLoaderWrapper keyPrefix="total" loading={!exchangeRate}>
            <motion.span {...lineItemValueAnimateProps}>
              <div className="font-bold tabular-nums tracking-tight">
                {formatCurrency(total?.amount, total?.currency)}
              </div>
            </motion.span>
          </SkeletonLoaderWrapper>
        </AnimatePresence>
      </div>
    </div>
  );
};
