import { FrameCardTokenizedEvent } from "frames-react";
import { err, ok, Result } from "neverthrow";
import { ErrorMessages } from "../errorMessages";
import {
  AllowedEEAIssuingBankCountryCodes,
  CountryCodeAlpha2,
} from "../../types";

export type TokenizationErrorReason =
  | "invalid_card_type"
  | "invalid_card_scheme"
  | "invalid_card_category"
  | "invalid_card_issuer_country";

export const validateTokenizationResult = (
  tokenizationResult: FrameCardTokenizedEvent,
  /** The allowed issuing bank country codes in [ISO-3166](https://en.wikipedia.org/wiki/ISO_3166) 2 character format. */
  allowedIssuingBankCountries:
    | AllowedEEAIssuingBankCountryCodes[]
    | [CountryCodeAlpha2.US],
): Result<
  { token: string },
  | { message: string; reason: TokenizationErrorReason }
  | { reason: "invalid_card_issuer_country" }
> => {
  if (tokenizationResult.card_type?.toLowerCase() !== "debit") {
    return err({
      message: ErrorMessages.addPaymentCard.DEBIT_CARDS_ONLY_ERROR,
      reason: "invalid_card_type",
    });
  }

  if (
    !tokenizationResult.scheme ||
    !["visa", "mastercard"].includes(tokenizationResult.scheme.toLowerCase())
  ) {
    return err({
      message: ErrorMessages.addPaymentCard.UNSUPPORTED_CARD_SCHEME_ERROR,
      reason: "invalid_card_scheme",
    });
  }

  if (tokenizationResult.card_category?.toLowerCase() !== "consumer") {
    return err({
      message: ErrorMessages.addPaymentCard.CONSUMER_CARDS_ONLY_ERROR,
      reason: "invalid_card_category",
    });
  }

  if (
    !tokenizationResult.issuer_country ||
    !allowedIssuingBankCountries.some(
      (countryCode) => countryCode === tokenizationResult.issuer_country,
    )
  ) {
    return err({
      reason: "invalid_card_issuer_country",
    });
  }

  return ok({ token: tokenizationResult.token });
};
