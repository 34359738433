import { ProfileSettingsChangePassword } from "./ProfileSettingsChangePassword";
import { ProfileSettingsTheme } from "./ProfileSettingsTheme";
import { ProfileSettingsWallets } from "./ProfileSettingsWallets";
import { Card } from "./Card";
import { motion } from "framer-motion";
import { ProfileSettingsPaymentMethods } from "./ProfileSettingsPaymentMethods";
import { useAppContext } from "../hooks/useAppContext";
import { useMemo } from "react";
import { ProfileSettingsDownloadTransferHistory } from "./ProfileSettingsDownloadTransferHistory";

const IN_REVIEW_STATUSES = ["in_review", "in_review_blocked"];

export const ProfileSettings = () => {
  const { session } = useAppContext();
  const status = session?.user?.status || "";

  const visibleProfileSettings = useMemo(() => {
    const orderedSettings = [
      { component: <ProfileSettingsTheme /> },
      { component: <ProfileSettingsChangePassword /> },
      { component: <ProfileSettingsWallets />, only: ["active"] },
      { component: <ProfileSettingsPaymentMethods /> },
      { component: <ProfileSettingsDownloadTransferHistory /> },
    ];

    return orderedSettings
      .filter((c) => !c.only || c.only.includes(status))
      .map((c) => c.component);
  }, [status]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {IN_REVIEW_STATUSES.includes(status) && (
        <div
          data-testid="InReviewBanner"
          className="mx-2 mb-2 rounded-2xl bg-white p-4 text-center text-sm font-semibold text-neutral-800 shadow-lg md:mx-0 dark:bg-neutral-700 dark:text-white"
        >
          Your account is currently under review, but you may still update your
          settings below.
        </div>
      )}
      <Card>
        {visibleProfileSettings.map((component, index) => (
          <div key={index} data-testid="ProfileSetting">
            {component}
            {index < visibleProfileSettings.length - 1 && (
              <hr className="my-4 opacity-50 md:my-8 dark:opacity-20" />
            )}
          </div>
        ))}
      </Card>
    </motion.div>
  );
};
