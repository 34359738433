import { AnimatePresence, motion, Variants } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Title, Text, Button } from "@tigris/mesokit";
import { formatDistance } from "date-fns";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useAppContext } from "../hooks/useAppContext";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { AddDebitCardForm, AddDebitCardFormProps } from "./AddDebitCard";
import { Dialog, DialogPanel } from "@headlessui/react";
import { resolveUser } from "../api";
import { toast } from "sonner";
import { User, UserStatus } from "../types";
import { Posthog, TelemetryEvents } from "@tigris/common";
import { ErrorMessages } from "../utils/errorMessages";

const paymentMethodInputAnimation = {
  initial: {
    opacity: 0,
    y: 10,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
  exit: { opacity: 0, y: 10 },
};

const paymentMethodItemAnimation = {
  initial: {
    opacity: 0,
    y: -24,
    height: "0px",
  },
  animate: {
    opacity: 1,
    y: 0,
    height: "auto",
  },
  exit: { opacity: 0 },
};

const skeletonLineVariants: Variants = {
  initial: { opacity: 0 },
  animate: {
    opacity: [0.4, 0.8, 0.4],
    transition: {
      repeat: Infinity,
      repeatType: "loop",
      duration: 1.4,
      ease: "linear",
    },
  },
  exit: { opacity: 0 },
};

const skeletonClassName =
  "bg-gray-400 text-gray-400 rounded-3xl opacity-20 select-none";

const ProfileSettingsPaymentMethodsLayout = ({
  children,
  showAddPaymentMethodForm,
  onClose,
  onSuccess,
  user,
}: PropsWithChildren<{
  showAddPaymentMethodForm: boolean;
  onClose: (value: boolean) => void;
  onSuccess: AddDebitCardFormProps["onSuccess"];
  user: User;
}>) => {
  return (
    <div
      className="flex flex-col gap-4"
      data-testid="ProfileSettingsPaymentMethods"
    >
      <section>
        <Title.Medium className="font-bold">Debit Card</Title.Medium>
        <Text>You can update your existing debit card at any time.</Text>
      </section>
      {children}

      <AnimatePresence>
        <Dialog
          as={motion.div}
          className="relative z-50"
          open={showAddPaymentMethodForm}
          onClose={onClose}
        >
          {/* Backdrop */}
          <div
            className="fixed inset-0 bg-black/30 backdrop-blur"
            aria-hidden="true"
          />

          <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
            <DialogPanel className="rounded-ts-card w-ts-card mx-auto h-[520px] max-w-sm bg-white p-6 dark:bg-neutral-800">
              <AddDebitCardForm onSuccess={onSuccess} user={user} />
            </DialogPanel>
          </div>
        </Dialog>
      </AnimatePresence>
    </div>
  );
};

export const ProfileSettingsPaymentMethods = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showAddPaymentMethodForm, setShowAddPaymentMethodForm] =
    useState(false);
  const { session, updateUser, logout } = useAppContext();

  const paymentMethods =
    session?.user?.fiatInstruments?.__typename === "FiatInstruments"
      ? session.user.fiatInstruments.collection
      : [];

  const handleClose = useCallback(() => {
    setShowAddPaymentMethodForm(false);
  }, []);

  const handleSuccess = useCallback(async () => {
    setShowAddPaymentMethodForm(false);
    setIsLoading(true);

    const resolveUserResult = await resolveUser();

    if (resolveUserResult.isOk()) {
      if (resolveUserResult.value.status === UserStatus.FROZEN) {
        logout(new URLSearchParams({ frozenUser: "true" }).toString());
        return;
      }

      updateUser(resolveUserResult.value);

      setIsLoading(false);
    } else {
      toast.error(resolveUserResult.error);
    }
  }, [logout, updateUser]);

  useEffect(() => {
    if (!session?.user) {
      toast.error(ErrorMessages.GENERIC_ERROR);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!session?.user) {
    return null;
  }

  if (!isLoading && paymentMethods.length === 0) {
    return (
      <ProfileSettingsPaymentMethodsLayout
        user={session.user}
        onClose={handleClose}
        onSuccess={handleSuccess}
        showAddPaymentMethodForm={showAddPaymentMethodForm}
      >
        <section
          className="flex flex-col gap-4 rounded-2xl border p-6 text-center dark:border-neutral-700"
          data-testid="ProfileSettingsPaymentMethods:emptySection"
        >
          <div>
            <Title.Medium className="font-bold">
              No Debit Card Connected
            </Title.Medium>
            <Text>
              You can add a debit card here, or one will be added the next time
              you make a transfer.
            </Text>
          </div>
          <motion.div key="link-wallet-button" {...paymentMethodInputAnimation}>
            <Button
              className="px-4 text-sm font-bold"
              containerClassName="block"
              primary={false}
              onClick={() => {
                Posthog.capture(
                  TelemetryEvents.accountSettingsAddDebitCardButtonClick,
                );
                setShowAddPaymentMethodForm(true);
              }}
              data-testid="ProfileSettingsPaymentMethods:emptySection:button"
            >
              Add a Debit Card
            </Button>
          </motion.div>
        </section>
      </ProfileSettingsPaymentMethodsLayout>
    );
  }

  return (
    <ProfileSettingsPaymentMethodsLayout
      user={session.user}
      onClose={handleClose}
      onSuccess={handleSuccess}
      showAddPaymentMethodForm={showAddPaymentMethodForm}
    >
      <section>
        <ul className="flex flex-col gap-2">
          {isLoading ? (
            <AnimatePresence key="skeleton" mode="wait">
              <motion.li
                key={`li-skeleton`}
                {...paymentMethodItemAnimation}
                className="rounded-2xl border dark:border-neutral-700"
              >
                <div className="p-2 md:p-6" data-testid="Wallet">
                  <div className="flex items-center gap-2 text-neutral-800 dark:text-white">
                    <FontAwesomeIcon
                      icon={icon({
                        name: "credit-card",
                        style: "solid",
                      })}
                      className="mr-2 scale-125 transition-transform group-hover:scale-100"
                    />
                    <div className="flex flex-grow flex-col gap-0">
                      <div className="flex items-center gap-2">
                        <div>
                          <motion.span
                            key="skeleton:maskedNumberLoader"
                            data-testid="skeleton:maskedNumberLoader"
                            variants={skeletonLineVariants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            className={skeletonClassName}
                          >
                            ------
                          </motion.span>
                        </div>
                      </div>
                      <div className="flex items-center text-xs font-semibold tracking-tight">
                        <motion.span
                          key="skeleton:createdAtLoader"
                          data-testid="skeleton:createdAtLoader"
                          variants={skeletonLineVariants}
                          initial="initial"
                          animate="animate"
                          exit="exit"
                          className={skeletonClassName}
                        >
                          ----- ----- - ----- ---
                        </motion.span>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.li>
            </AnimatePresence>
          ) : (
            paymentMethods.map((paymentMethod) => {
              return (
                <AnimatePresence key={paymentMethod.id} mode="wait">
                  <motion.li
                    key={`li-${paymentMethod.id}`}
                    {...paymentMethodItemAnimation}
                    className="rounded-2xl border dark:border-neutral-700"
                  >
                    <div className="p-2 md:p-6" data-testid="Wallet">
                      <div className="flex items-center gap-2 text-neutral-800 dark:text-white">
                        <FontAwesomeIcon
                          icon={icon({
                            name: "credit-card",
                            style: "solid",
                          })}
                          className="mr-2 scale-125 transition-transform group-hover:scale-100"
                        />
                        <div className="flex flex-grow flex-col gap-0">
                          <div className="flex items-center gap-2">
                            <div
                              data-testid="Wallet:Address"
                              className="font-mono text-sm"
                            >
                              <Text
                                className="whitespace-normal break-words font-mono md:text-base"
                                data-testid="ProfileSettingsPaymentMethods:maskedNumber"
                              >
                                Debit Card ending in{" "}
                                {paymentMethod.maskedNumber}
                              </Text>
                            </div>
                          </div>
                          <div className="flex items-center text-xs font-semibold tracking-tight">
                            <Text
                              className="opacity-60"
                              data-testid="ProfileSettingsPaymentMethods:addedAt"
                            >
                              Added{" "}
                              {formatDistance(
                                new Date(paymentMethod.createdAt),
                                Date.now(),
                                { addSuffix: true },
                              )}
                            </Text>
                          </div>
                        </div>
                      </div>
                    </div>
                  </motion.li>
                </AnimatePresence>
              );
            })
          )}
        </ul>

        <Button
          className="mt-4 px-4 text-sm font-bold"
          containerClassName="block"
          primary={false}
          onClick={() => {
            Posthog.capture(
              TelemetryEvents.accountSettingsUpdateDebitCardButtonClick,
            );
            setShowAddPaymentMethodForm(true);
          }}
          disabled={isLoading || !session?.user}
          data-testid="ProfileSettingsPaymentMethods:button"
        >
          Update Debit Card
        </Button>
      </section>
    </ProfileSettingsPaymentMethodsLayout>
  );
};
