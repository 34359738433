import * as Factory from "factory.ts";
import { UserFragment } from "../generated/sdk";
import { CountryCodeAlpha2 } from "../types";

export const residentialAddressFactory = Factory.Sync.makeFactory<
  UserFragment["residentialAddress"]
>({
  __typename: "Address",
  address1: "123 Street",
  address2: "unit 6",
  city: "Joliet",
  region: "IL",
  postalCode: "60123",
  countryCode: CountryCodeAlpha2.US,
});

export const internationalResidentialAddressFactory = Factory.Sync.makeFactory<
  UserFragment["residentialAddress"]
>({
  __typename: "Address",
  address1: "ulica Bohaterów Warszawy 33",
  city: "Ińsko",
  region: "Zachodniopomorskie",
  postalCode: "73-140",
  countryCode: CountryCodeAlpha2.PL,
});
