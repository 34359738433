import { toast } from "sonner";
import { ErrorMessages } from "./errorMessages";
import { useNavigate } from "react-router-dom";
import { AccountAppRoutes } from "./constants";
import { ResponseMiddleware } from "graphql-request";
import { GraphQLClientResponse } from "../types";

const responseMiddleware = (navigate: ReturnType<typeof useNavigate>) => {
  const middleWare: ResponseMiddleware = (
    response: GraphQLClientResponse | Error,
  ) => {
    const twoFactorErr =
      !(response instanceof Error) &&
      response.errors?.some((e) => e.message === "requires two factors");

    if (twoFactorErr) {
      setTimeout(
        () =>
          toast.error(ErrorMessages.AUTHENTICATION_EXPIRED, {
            id: "AUTHENTICATION_EXPIRED_CTA",
            action: {
              label: "Reload",
              onClick: (event: React.MouseEvent) => {
                event.preventDefault();
                navigate(AccountAppRoutes.LOGIN_2FA);
              },
            },
            onDismiss: () => navigate(AccountAppRoutes.LOGIN_2FA),
          }),
        500,
      );
    }
  };
  return middleWare;
};

export default responseMiddleware;
