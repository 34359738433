import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccountAppRoutes } from "../../utils/constants";
import { internationalUserFactory, userFactory } from "../../factories/User";
import { twMerge } from "tailwind-merge";
import { AppContext } from "../../contexts/AppContext";

const AUTO_LOGIN_KEY = "autoLogin";

export const DevOnlyControls = () => {
  const navigate = useNavigate();
  const { setSession } = useContext(AppContext);
  const [autoLoginEnabled, setAutoLoginEnabled] = useState<boolean>(() => {
    const enabled = sessionStorage.getItem(AUTO_LOGIN_KEY);

    return enabled === "true";
  });

  const toggleAutoLogin = useCallback(
    (userVariant: "international" | "us") => () => {
      if (autoLoginEnabled) {
        sessionStorage.removeItem(AUTO_LOGIN_KEY);
        setAutoLoginEnabled(false);
        navigate(AccountAppRoutes.ROOT);
      } else {
        sessionStorage.setItem(AUTO_LOGIN_KEY, "true");
        setAutoLoginEnabled(true);
        setSession({
          id: "stub",
          token: "stub",
          riskSessionKey: "stub",
          user:
            userVariant === "us"
              ? userFactory.build()
              : internationalUserFactory.build(),
        });
        navigate(AccountAppRoutes.PROFILE_SETTINGS);
      }
    },
    [autoLoginEnabled, navigate, setSession],
  );

  if (!import.meta.env.DEV) return;

  return (
    <div className="fixed bottom-10 right-10 rounded-xl bg-black/[.6] p-3">
      <div className="flex flex-col gap-2">
        <div className="flex w-full items-center gap-1 border-b border-b-neutral-700 pb-2 text-xs font-bold">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="currentColor"
            className="size-4"
          >
            <path
              fillRule="evenodd"
              d="M11.5 8a3.5 3.5 0 0 0 3.362-4.476c-.094-.325-.497-.39-.736-.15L12.099 5.4a.48.48 0 0 1-.653.033 8.554 8.554 0 0 1-.879-.879.48.48 0 0 1 .033-.653l2.027-2.028c.24-.239.175-.642-.15-.736a3.502 3.502 0 0 0-4.476 3.427c.018.99-.133 2.093-.914 2.7l-5.31 4.13a2.015 2.015 0 1 0 2.828 2.827l4.13-5.309c.607-.78 1.71-.932 2.7-.914L11.5 8ZM3 13.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
              clipRule="evenodd"
            />
          </svg>
          DevTools
        </div>

        {autoLoginEnabled ? (
          <button
            className={twMerge(
              "rounded-ts-card transition-al px-4 py-2 text-sm",
              autoLoginEnabled && "bg-neutral-800",
              !autoLoginEnabled && "bg-neutral-800",
            )}
            onClick={toggleAutoLogin("us")}
          >
            <div className="flex items-center gap-2 align-middle">
              <div
                className={twMerge(
                  "h-[10px] w-[10px] rounded-full",
                  autoLoginEnabled ? "bg-green-500" : "bg-red-500",
                )}
              />
              {autoLoginEnabled ? "Disable Auto Login" : "Login (US user)"}
            </div>
          </button>
        ) : (
          <>
            <button
              className={twMerge(
                "rounded-ts-card transition-al px-4 py-2 text-sm",
                autoLoginEnabled && "bg-neutral-800",
                !autoLoginEnabled && "bg-neutral-800",
              )}
              onClick={toggleAutoLogin("us")}
            >
              <div className="flex items-center gap-2 align-middle">
                <div
                  className={twMerge(
                    "h-[10px] w-[10px] rounded-full",
                    autoLoginEnabled ? "bg-green-500" : "bg-red-500",
                  )}
                />
                {autoLoginEnabled ? "Disable Auto Login" : "Login (US user)"}
              </div>
            </button>
            <button
              className={twMerge(
                "rounded-ts-card transition-al px-4 py-2 text-sm",
                autoLoginEnabled && "bg-neutral-800",
                !autoLoginEnabled && "bg-neutral-800",
              )}
              onClick={toggleAutoLogin("international")}
            >
              <div className="flex items-center gap-2 align-middle">
                <div
                  className={twMerge(
                    "h-[10px] w-[10px] rounded-full",
                    autoLoginEnabled ? "bg-green-500" : "bg-red-500",
                  )}
                />
                {autoLoginEnabled ? "Disable Auto Login" : "Login (Int'l user)"}
              </div>
            </button>
          </>
        )}
      </div>
    </div>
  );
};
