export const formatCurrency = (
  amount?: string | number,
  currency?: string,
): string =>
  new Intl.NumberFormat(navigator.language, {
    style: "currency",
    currency: currency ?? "USD",
    currencyDisplay: "narrowSymbol",
  }).format(!amount ? 0 : Number(amount));

export const formatCurrencySymbol = (currency?: string): string => {
  const formatter = new Intl.NumberFormat(navigator.language, {
    style: "currency",
    currency: currency ?? "USD",
    currencyDisplay: "narrowSymbol",
  });

  const parts = formatter.formatToParts(0);
  const symbol = parts.find((part) => part.type === "currency")?.value ?? "$";
  return symbol;
};
