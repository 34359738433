import { PropsWithChildren, useState, useCallback } from "react";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { AnimatePresence, motion } from "framer-motion";
import { twMerge } from "tailwind-merge";

const copyAnimation = {
  initial: { opacity: 0, scale: 0.8 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.8 },
  transition: { duration: 0.1 },
};

export const CopyToClipboard = ({
  text,
  size,
  className,
  containerClassName,
  children,
}: PropsWithChildren<{
  text: string;
  size?: FontAwesomeIconProps["size"];
  containerClassName?: string;
  className?: string;
}>) => {
  const [copied, setCopied] = useState(false);

  const handleClick = useCallback(() => {
    if (copied) return;

    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 1_500);
    });
  }, [copied, text]);

  return (
    <div
      data-testid="CopyToClipboard"
      onClick={handleClick}
      className={twMerge("flex items-center", containerClassName)}
    >
      <AnimatePresence mode="wait">
        {copied ? (
          <motion.div
            data-testid="CopyToClipboardCheckIcon"
            key={`copy-${text}-check`}
            className="cursor-default"
            {...copyAnimation}
          >
            <FontAwesomeIcon
              icon={icon({ name: "check", style: "regular" })}
              size={size}
              className={className}
            />
          </motion.div>
        ) : (
          <motion.div
            data-testid="CopyToClipboardCopyIcon"
            key={`copy-${text}-copy`}
            className="cursor-pointer"
            whileTap={{ scale: 0.9 }}
            {...copyAnimation}
          >
            <FontAwesomeIcon
              icon={icon({ name: "copy", style: "solid" })}
              size={size}
              className={className}
            />
          </motion.div>
        )}
      </AnimatePresence>
      {children}
    </div>
  );
};
