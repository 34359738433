import { toast } from "sonner";
import { ErrorMessages } from "./errorMessages";
import { UnsupportedIssuingCountryToastError } from "../components/UnsupportedIssuingCountryToastError";
import { AddPaymentCardErrorCode } from "../types";

export const addPaymentCardToastError = (
  errorCode: AddPaymentCardErrorCode,
): Parameters<typeof toast.error>[0] => {
  let toastError: Parameters<typeof toast.error>[0] =
    ErrorMessages.addPaymentCard.ADD_PAYMENT_CARD_GENERIC_API_ERROR;

  switch (errorCode) {
    case "user_blocked":
      toastError = ErrorMessages.addPaymentCard.USER_BLOCKED_ERROR;
      break;

    case "unsupported_issuing_country":
      toastError = <UnsupportedIssuingCountryToastError />;
      break;

    case "unsupported_card_type":
      toastError = ErrorMessages.addPaymentCard.DEBIT_CARDS_ONLY_ERROR;
      break;

    case "invalid_card_information":
      toastError =
        ErrorMessages.addPaymentCard.ADD_PAYMENT_CARD_GENERIC_API_ERROR;
      break;
    case "card_cannot_be_processed":
      toastError = ErrorMessages.addPaymentCard.CARD_CANNOT_BE_PROCESSED_ERROR;
      break;
  }

  return toastError;
};
