import ARBITRUM_NETWORK from "../../assets/network-logos/Arbitrum-Badge.svg";
import ETH_NETWORK from "../../assets/network-logos/Ethereum-Badge.svg";
import OPTIMISM_NETWORK from "../../assets/network-logos/Optimism-Badge.svg";
import POLYGON_NETWORK from "../../assets/network-logos/Polygon-Badge.svg";
import SOL_NETWORK from "../../assets/network-logos/Solana-Badge.svg";
import BTC_NETWORK from "../../assets/network-logos/Bitcoin-Badge.svg";
import BASE_NETWORK from "../../assets/network-logos/Base-Badge.svg";
import { FiatInstrument } from "../FiatInstrument";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useContext, useMemo, useState } from "react";
import { getFiatDisplayValue, getFiatType } from "../../utils/fiatInstrument";
import {
  TransferKind,
  FiatInstrument as FiatInstrumentT,
  FiatInstruments,
  SingleUseInstrument,
} from "../../generated/schema";
import { Logo } from "../Logo";
import { Network } from "../../types";
import { Text } from "../Text";
import { WalletInstrument } from "../WalletInstrument";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { AnimatePresence, motion } from "framer-motion";
import { twMerge } from "tailwind-merge";
import {
  CloseButton,
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import { MesoKitContext } from "../../MesoKitContext";
import { ApplePayTooltip } from "./ApplePayTooltip";
import { PaymentMethodTooltip } from "./PaymentMethodTooltip";
import { ChangePaymentMethodTooltip } from "./ChangePaymentMethodTooltip";
import { Confirmation } from "../Confirmation";
import { TelemetryEvents } from "@tigris/common";
import { useImpressionTracker } from "@tigris/mesokit";

const NetworkBadge = ({ network }: { network: Network }) => {
  const networkBadges = {
    [Network.ETHEREUM_MAINNET]: ETH_NETWORK,
    [Network.SOLANA_MAINNET]: SOL_NETWORK,
    [Network.ARBITRUM_MAINNET]: ARBITRUM_NETWORK,
    [Network.OP_MAINNET]: OPTIMISM_NETWORK,
    [Network.POLYGON_MAINNET]: POLYGON_NETWORK,
    [Network.BITCOIN_MAINNET]: BTC_NETWORK,
    [Network.BASE_MAINNET]: BASE_NETWORK,
  };

  return (
    <div
      data-testid={`${network}NetworkBadges`}
      className="size-9 bg-neutral-600 dark:bg-neutral-200"
      style={{
        WebkitMask: `url(${networkBadges[network]}) 50% 50% / contain no-repeat`,
        mask: `url(${networkBadges[network]}) 50% 50% / contain no-repeat`,
      }}
    />
  );
};

const ProfileDetails = ({
  walletAddress,
  fiatInstrument,
  fiatInstruments,
  setFiatInstrument,
  depositAddress,
  transferKind,
  network,
  setIsAddingCard,
  authenticated,
  onRemoveFiatInstrument,
  applePayEnabled = false,
  applePayCanceled = false,
  canShowPopover = true,
  disableDepositAddressPopover = false,
}: {
  walletAddress: string;
  fiatInstrument?: FiatInstrumentT | SingleUseInstrument;
  fiatInstruments?: FiatInstruments;
  setFiatInstrument?: (
    instrument: FiatInstrumentT | SingleUseInstrument,
  ) => void;
  depositAddress?: string;
  transferKind: TransferKind;
  network: Network;
  authenticated: boolean;
  applePayEnabled?: boolean;
  applePayCanceled?: boolean;
  canShowPopover?: boolean;
  disableDepositAddressPopover?: boolean;
  setIsAddingCard?: (connecting: boolean) => void;
  onRemoveFiatInstrument?: (id: string) => void;
}) => {
  const { posthog } = useContext(MesoKitContext);
  const [showPopover, setShowPopover] = useState<
    "wallet" | "fiat" | "depositAddress"
  >();
  const [trashedFiatInstrument, setTrashedFiatInstrument] =
    useState<FiatInstrumentT>();
  const { getImpressionCount, trackImpression } = useImpressionTracker();
  const networkName = (Object.entries(Network).find(
    ([_, v]) => v === network,
  ) || [""])[0]
    .split("_")
    .map((name) => name.charAt(0).toUpperCase() + name.substr(1).toLowerCase())
    .join(" ");
  const isCashIn = transferKind === TransferKind.CASH_IN;

  const sourceClassName =
    "flex h-7 flex-auto items-center gap-1 font-bold outline-none ring-0";
  const destinationClassName = canShowPopover
    ? "profile-capsule-inner font-bold group cursor-pointer rounded-full bg-transparent py-1 px-3 h-full outline-none ring-0"
    : "profile-capsule-inner font-bold rounded-full bg-transparent py-1 pl-1 pr-3 outline-none ring-0";
  const popoverClassName = twMerge(
    "absolute z-20 mt-9 flex w-80 flex-col justify-center items-center gap-1 rounded-[16px] border border-transparent p-4 shadow-xl network-popover",
  );

  const fiatDetails = (
    <Popover className="relative">
      <PopoverButton
        as={Fragment}
        data-testid="FiatDetails"
        disabled={!canShowPopover}
      >
        <div
          className={twMerge(
            "flex items-center gap-2",
            canShowPopover
              ? "cursor-pointer transition-transform active:scale-95"
              : "",
          )}
          onClick={() => {
            if (!canShowPopover) {
              return;
            } else if (showPopover === "fiat") {
              // hide fiat instrument popover
              setShowPopover(undefined);
            } else {
              // show fiat instrument popover
              setShowPopover("fiat");

              // capture event if showing the payment method selector
              if (authenticated) {
                trackImpression("fiatInstrumentSelectorShow");
                posthog?.capture(TelemetryEvents.fiatInstrumentSelectorShow);
              }
            }
          }}
        >
          <FiatInstrument
            className={isCashIn ? sourceClassName : destinationClassName}
            cardScheme={getFiatType(fiatInstrument)}
            display={getFiatDisplayValue(fiatInstrument)}
          />
          {canShowPopover && (
            <div className="capsule-popover-button">
              <FontAwesomeIcon
                icon={icon({ name: "chevron-down", style: "solid" })}
              />
            </div>
          )}
        </div>
      </PopoverButton>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        {authenticated ? (
          <PopoverPanel
            data-testid="FiatPopover"
            anchor="bottom"
            className="network-popover z-20 flex-col items-center gap-1 rounded-[16px] border p-2 text-xs font-semibold text-black shadow-lg dark:border-neutral-700 dark:text-white"
          >
            <div className="w-48 border-b p-3 font-bold dark:border-b-neutral-600">
              Payment Methods
            </div>
            <div className="pt-2">
              {fiatInstruments &&
                fiatInstruments.collection.map((fi) => {
                  return (
                    <CloseButton
                      as="div"
                      key={`popover-${fi.id}`}
                      data-testid={"FiatPopoverFiatInstrument"}
                      className="group flex cursor-pointer items-center justify-between rounded-lg p-2 hover:bg-neutral-100 dark:hover:bg-neutral-700"
                      onClick={() => {
                        posthog?.capture(
                          TelemetryEvents.fiatInstrumentSelectorClick,
                          { fiatType: getFiatType(fi) },
                        );
                        setFiatInstrument && setFiatInstrument(fi);
                        setShowPopover(undefined);
                      }}
                    >
                      <div className="flex items-center gap-1">
                        {fiatInstrument &&
                          typeof fiatInstrument === "object" &&
                          fi.id === fiatInstrument.id && (
                            <FontAwesomeIcon
                              icon={icon({ name: "check-circle" })}
                              className="text-primary dark:text-primary-light"
                              size="lg"
                            />
                          )}
                        <FiatInstrument
                          cardScheme={getFiatType(fi)}
                          display={getFiatDisplayValue(fi)}
                        />
                      </div>
                      {onRemoveFiatInstrument && (
                        <FontAwesomeIcon
                          data-testid={"RemoveFiatInstrumentIcon"}
                          onClick={(event) => {
                            event.preventDefault();
                            posthog?.capture(
                              TelemetryEvents.fiatInstrumentRemoveDebitCardClick,
                            );
                            setTrashedFiatInstrument(fi);
                          }}
                          icon={icon({ name: "trash" })}
                          className={twMerge(
                            "text-neutral-700 group-hover:block dark:text-neutral-200",
                            window.ontouchstart || navigator.maxTouchPoints > 0
                              ? ""
                              : "hidden",
                          )}
                          size="lg"
                        />
                      )}
                    </CloseButton>
                  );
                })}
            </div>
            {applePayEnabled && setFiatInstrument && (
              <CloseButton
                as="div"
                onClick={() => {
                  posthog?.capture(
                    TelemetryEvents.fiatInstrumentSelectorClick,
                    { fiatType: getFiatType(SingleUseInstrument.APPLE_PAY) },
                  );
                  setFiatInstrument(SingleUseInstrument.APPLE_PAY);
                  setShowPopover(undefined);
                }}
                className="flex cursor-pointer items-center justify-between rounded-lg p-2 hover:bg-neutral-100 dark:hover:bg-neutral-700"
              >
                <div className="flex items-center gap-1">
                  {fiatInstrument === SingleUseInstrument.APPLE_PAY && (
                    <FontAwesomeIcon
                      icon={icon({ name: "check-circle" })}
                      className="text-primary"
                      size="lg"
                    />
                  )}
                  <FiatInstrument
                    cardScheme={getFiatType(SingleUseInstrument.APPLE_PAY)}
                    display={getFiatDisplayValue(SingleUseInstrument.APPLE_PAY)}
                  />
                </div>
              </CloseButton>
            )}
            {setIsAddingCard && (
              <CloseButton
                as="div"
                data-testid="FiatPopoverAddDebitCard"
                onClick={() => {
                  posthog?.capture(
                    TelemetryEvents.fiatInstrumentAddDebitCardClick,
                  );
                  setIsAddingCard(true);
                  setShowPopover(undefined);
                }}
                className="flex cursor-pointer items-center gap-1 rounded-lg p-2 hover:bg-neutral-100 dark:hover:bg-neutral-700"
              >
                <FontAwesomeIcon
                  className="h-[15px] w-[15px]"
                  icon={icon({ name: "plus", style: "light" })}
                />
                <Text className="text-xs font-medium">Add Debit Card</Text>
              </CloseButton>
            )}
          </PopoverPanel>
        ) : (
          <PopoverPanel
            data-testid="FiatPopover"
            anchor="bottom"
            className="network-popover mt-1 flex items-center gap-2 rounded-[16px] border border-transparent p-4 shadow-xl"
          >
            <FontAwesomeIcon
              icon={icon({ name: "credit-card", style: "solid" })}
              className="text-neutral-800 dark:text-white"
            />
            <div className="flex flex-col">
              <Text className="text-xs font-semibold">
                Log in to see your payment method
              </Text>
            </div>
          </PopoverPanel>
        )}
      </Transition>
    </Popover>
  );

  const walletDetails = (
    <Popover className="relative">
      <PopoverButton
        className={isCashIn ? destinationClassName : sourceClassName}
        data-testid="WalletDetails"
        disabled={!canShowPopover}
      >
        <WalletInstrument
          network={network}
          walletAddress={walletAddress}
          className="font-bold group-active:scale-95"
        />
      </PopoverButton>

      <PopoverPanel
        transition
        anchor="bottom"
        data-testid="WalletPopover"
        className="network-popover flex flex-col items-center gap-2 rounded-[16px] border border-transparent p-4 shadow-xl transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
      >
        <NetworkBadge network={network} />
        <div className="flex flex-col">
          <Text className="text-center text-sm font-semibold">
            {networkName}
          </Text>
          <Text className="whitespace-normal break-all text-center font-mono text-[10px] font-bold">
            {walletAddress}
          </Text>
        </div>
      </PopoverPanel>
    </Popover>
  );

  const tooltip = useMemo(() => {
    if (!authenticated || !canShowPopover || showPopover) {
      return;
    }

    const showApplePayTooltip =
      applePayEnabled &&
      setFiatInstrument &&
      fiatInstrument &&
      fiatInstrument !== SingleUseInstrument.APPLE_PAY;

    const showPaymentMethodTooltip = !fiatInstrument && setIsAddingCard;

    return (
      <AnimatePresence>
        {applePayCanceled &&
        getImpressionCount("fiatInstrumentSelectorShow") === 0 ? (
          <ChangePaymentMethodTooltip />
        ) : showApplePayTooltip && setFiatInstrument ? (
          <ApplePayTooltip setFiatInstrument={setFiatInstrument} />
        ) : showPaymentMethodTooltip && setIsAddingCard ? (
          <PaymentMethodTooltip setIsAddingCard={() => setIsAddingCard(true)} />
        ) : null}
      </AnimatePresence>
    );
  }, [
    applePayCanceled,
    applePayEnabled,
    authenticated,
    canShowPopover,
    fiatInstrument,
    getImpressionCount,
    setFiatInstrument,
    setIsAddingCard,
    showPopover,
  ]);

  return (
    <motion.div
      layout
      data-testid="ProfileDetails"
      className="relative flex flex-col items-center gap-2"
    >
      <div className="capsule-container">
        <div className="capsule-source">
          {isCashIn ? fiatDetails : walletDetails}
        </div>

        {!isCashIn && (
          <div
            onClick={() =>
              canShowPopover &&
              !disableDepositAddressPopover &&
              setShowPopover(
                showPopover === "depositAddress" ? undefined : "depositAddress",
              )
            }
            data-testid="DepositAddress"
            className="flex items-center pl-1 pr-2"
          >
            <Logo size="xs" showText={false} />
          </div>
        )}
        {!isCashIn && (
          <motion.div
            animate={{
              opacity: [0, 0.5, 0],
              x: [-12, 0, 0],
              transition: {
                delay: 1,
                repeatDelay: 2,
                ease: "easeInOut",
                duration: 1.5,
                repeat: Infinity,
                repeatType: "loop",
              },
            }}
          >
            <FontAwesomeIcon
              icon={icon({ name: "chevron-right", style: "regular" })}
            />
          </motion.div>
        )}
        <div className="capsule-destination">
          {isCashIn ? walletDetails : fiatDetails}
        </div>
      </div>

      {tooltip}

      {canShowPopover && !disableDepositAddressPopover && (
        <Transition
          as={Fragment}
          show={showPopover === "depositAddress"}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div data-testid="DepositAddressPopover" className={popoverClassName}>
            <Logo size="sm" showText={false} />
            <div className="flex flex-col">
              <Text className="text-sm font-semibold">
                Meso Deposit Address
              </Text>
              {authenticated ? (
                <div className="flex items-center gap-1">
                  {!depositAddress && (
                    <FontAwesomeIcon
                      icon={icon({ name: "loader" })}
                      size="xs"
                      className="fa-spin"
                    />
                  )}
                  <Text
                    className={twMerge(
                      "whitespace-normal break-words text-[10px]",
                      depositAddress && "font-mono font-bold",
                    )}
                  >
                    {depositAddress ??
                      "Creating deposit address. This may take a minute..."}
                  </Text>
                </div>
              ) : (
                <Text className="whitespace-normal break-words text-[10px] font-bold">
                  Log in to see your deposit address
                </Text>
              )}
            </div>
          </div>
        </Transition>
      )}
      <Confirmation
        open={!!trashedFiatInstrument}
        iconName="trash"
        body={
          trashedFiatInstrument
            ? `Remove ${getFiatType(trashedFiatInstrument)} debit card ending in ••` +
              `${getFiatDisplayValue(trashedFiatInstrument)!.substring(getFiatDisplayValue(trashedFiatInstrument)!.length - 2)}?`
            : ""
        }
        acceptButtonText="Remove"
        rejectButtonText="Cancel"
        onResult={(status) => {
          if (
            trashedFiatInstrument &&
            onRemoveFiatInstrument &&
            status === "accepted"
          ) {
            posthog?.capture(
              TelemetryEvents.fiatInstrumentRemoveDebitCardAccept,
            );
            onRemoveFiatInstrument(trashedFiatInstrument.id);
          } else {
            posthog?.capture(
              TelemetryEvents.fiatInstrumentRemoveDebitCardReject,
            );
          }
          setTrashedFiatInstrument(undefined);
        }}
        destructive={true}
      />
    </motion.div>
  );
};

export { ProfileDetails };
