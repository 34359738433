import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import { Text } from "@tigris/mesokit";
import { useMemo } from "react";
import { HelpLink } from "./HelpLink";
import { twMerge } from "tailwind-merge";
import { UserLimits } from "../types";
import { formatCurrency } from "../utils/formatCurrency";

type BuyLimitProps = {
  limits: UserLimits;
  collapsed?: boolean;
};

export const BuyLimit = ({ limits, collapsed }: BuyLimitProps) => {
  const width = useMemo(() => {
    return limits.percentUsed * 100;
  }, [limits.percentUsed]);

  const containerClasses = twMerge(
    "rounded-2xl border-neutral-200 bg-neutral-50 dark:bg-neutral-800",
    collapsed ? "px-2 h-6 flex items-center" : "p-3",
  );
  return (
    <div className={containerClasses}>
      {!collapsed && (
        <div className="flex justify-between font-semibold">
          <Text>
            Your buy limit <HelpLink articleId="17053929093531-Fees-Limits" />
          </Text>
          {limits.limitReached ? (
            <Text className="opacity-60">Limit reached</Text>
          ) : (
            <Text>
              Up to{" "}
              {formatCurrency(
                limits.monthlyAmountAvailable.amount,
                limits.monthlyAmountAvailable.currency,
              )}
            </Text>
          )}
        </div>
      )}
      <div className="flex w-full items-center justify-between">
        <div className="relative mr-2 w-full">
          <div className="h-2 overflow-hidden rounded-full bg-neutral-200/50 dark:bg-neutral-900/50">
            <motion.div
              layout
              initial={{
                width: "0%",
                opacity: 0,
              }}
              animate={{
                width: width + "%",
                opacity: width === 0 ? 0 : 1,
              }}
              style={{
                background: `linear-gradient(90deg, hsla(${width - 90}, 60%, 70%, 1), hsla(${(width - 100) * 1.5}, 70%, 55%, 1))`,
              }}
              transition={{ ease: "anticipate", duration: 1 }}
              className="h-full rounded-full"
            />
          </div>
          <AnimatePresence>
            {limits.limitReached && (
              <motion.div
                initial={{ scale: 0.5, opacity: 0 }}
                animate={{
                  scale: 1,
                  opacity: 1,
                  rotate: -12,
                  transition: { delay: 0.5 },
                }}
                exit={{ scale: 0.5, opacity: 0 }}
                className="absolute -right-1 -top-1 flex size-4 items-center justify-center rounded-full bg-neutral-100 dark:bg-neutral-800"
              >
                <FontAwesomeIcon
                  icon={icon({
                    name: "circle-exclamation",
                    style: "solid",
                  })}
                  size="xs"
                  className="text-[#E5726A]"
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        {collapsed ? (
          <Text className="flex shrink-0 grow-0 basis-auto text-[10px] font-semibold opacity-60">
            Buy limit{" "}
            {limits.limitReached
              ? "reached"
              : `${formatCurrency(limits.monthlyAmountAvailable.amount, limits.monthlyAmountAvailable.currency)}`}{" "}
            <HelpLink className="ml-1" articleId="17053929093531-Fees-Limits" />
          </Text>
        ) : (
          <Text className="flex shrink-0 grow-0 basis-auto text-[10px] font-semibold opacity-60">
            {formatCurrency(
              limits.monthlyAmountUsed.amount,
              limits.monthlyAmountUsed.currency,
            )}{" "}
            of{" "}
            {formatCurrency(
              limits.monthlyMaximumAmount.amount,
              limits.monthlyMaximumAmount.currency,
            )}
          </Text>
        )}
      </div>
    </div>
  );
};
